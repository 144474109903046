.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky; /* Make the navbar sticky */
  top: 0; /* Stick to the top of the page */
  background-color: #114c81; /* Ensure the background remains visible */
  z-index: 1000; /* Maintain visibility above other content */
}

/* Logo */
.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px;
}

/* Menu */
.menu {
  display: flex;
  gap: 15px;
  transition: transform 0.3s ease-in-out;
}

.menu-item {
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

/* Hamburger Menu */
.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  cursor: pointer;
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.hamburger-menu .bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu .bar.open:nth-child(2) {
  opacity: 0;
}

.hamburger-menu .bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .menu {
    position: absolute;
    top: 60px;
    right: 20px;
    flex-direction: column;
    background-color: #114c81;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
  }

  .menu.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .hamburger-menu {
    display: flex;
  }
}
