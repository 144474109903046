.about-us-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  color: #fff;
  font-family: 'Chewy', cursive;
}

.about-us-image-container {
  flex: 1;
  min-width: 300px;
  text-align: center;
  margin-bottom: 20px;
}

.about-us-image-container img {
  max-width: 100%;
  transition: transform 0.3s ease-in-out;
}

.about-us-image-container img:hover {
  transform: scale(1.05);
}

.about-us-text-container {
  flex: 2;
  padding: 0 20px;
}

.about-us-text-container p {
  font-family: 'frank-ruhl-libre';
  font-size: 1.4rem;
  line-height: 1.8;
  text-align: justify;
}

@media (max-width: 768px) {
  h2 {
    font-size: 2.5rem;
  }

  .about-us-container {
    flex-direction: column;
    align-items: center;
  }

  .about-us-text-container {
    font-size: 1.2rem;
    padding: 0 10px;
    
  }

  .about-us-image-container img {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 2rem;
  }

  .about-us-text-container {
    font-size: 1rem;
    line-height: 1.6;
  }

  .about-us-image-container img {
    max-width: 80%;
  }
}
